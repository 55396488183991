import { createStore } from 'vuex'
// import axios from 'axios';
import modules from './modules'

const store = createStore({
  state() {
    return {
      authenticatedUser: [],
      authenticatedUserId: [],
      forFamily: false,
      selfRegistered: null,
      disclosure_id: null,
      current_step: null,
      applicantCstep: null,
      isAccepted: false,
      notificationData: null,
      isDeclined: false,
    }
  },
  modules,


  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  // strict: process.env.NODE_ENV !== 'production',
})

export default store

