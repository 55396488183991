// import store from "@/state/store";

export default [
  {
    path: "/dashboard",
    name: "dashboardClient",
    meta: { authRequired: true, admin: false },
    component: () => import("./views/applicant/dashboard"),
  },
  // {
  //   path: "/",
  //   name: "default",
  //   meta: { authRequired: true },
  //   component: () => import("./views/applicant/old-records"),
  // },
  {
    path: "/Applicant-login",
    name: "login",
    component: () => import("./views/account/email"),
  },
  {
    path: "/login",
    name: "admin_login",
    component: () => import("./views/account/admin_login"),
  },
  {
    path: "/setting",
    name: "Settings",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/account/change_password"),
  },

  // {
  //   path: "/login",
  //   name: "login",
  //   component: () => import("./views/account/login"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  // {
  //   path: "/register",
  //   name: "Register",
  //   component: () => import("./views/account/register"),
  //   meta: {
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/forgot-password",
    name: "Forgot password",
    component: () => import("./views/account/forgot-password"),

  },
  {
    path: "/reset-password/:id",
    name: "Reset password",
    component: () => import("./views/account/reset-password"),

  },
  {
    path: "/logout",
    name: "logout",
    component: () => import("./views/account/logout"),
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute ? { name: "default" } : { ...routeFrom }
        );
      },
    },
  },
  // {
  //   path: "/404",
  //   name: "404",
  //   component: require("./views/utility/404").default,
  // },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  // {
  //   path: "*",
  //   redirect: "404",
  // },

  // Admin Start Routing

  {
    path: "/",
    name: "admin dashaboard",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/admin/admin_dashboard"),
  },


  {
    path: "/allApplicants/:id", // Route with a parameter named 'id'
    name: "all Applicant",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/admin/all_applicant"),
    props: true, // Pass route params as props to the component
  },
  {
    path: "/single_applicant/:id", // Route with a parameter named 'id'
    name: "single Applicant",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/admin/single_applicant"),
    props: true, // Pass route params as props to the component
  },
  {
    path: "/allStaff",
    name: "allStaff",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/admin/Staff_add"),
  },
  {
    path: "/applicants",
    name: "Applicant",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/admin/applicants"),
  },
  {
    path: "/adminNotifications",
    name: "Admin Notifications",
    meta: { authRequired: true, admin: true },
    component: () => import("./views/admin/admin_notifications"),
  },
  {
    path: "/payment",
    name: "Payment",
    meta: { authRequired: true  ,admin: false},
    component: () => import("./views/applicant/payment"),
  },
  {
    path: "/invoice",
    name: "Invoice",
    meta: { authRequired: true  ,admin: true },
    component: () => import("./views/admin/invoice"),
  },
  // Applicant Form Routes
  {
    path: "/d2/:id",
    name: "D2",
    meta: { authRequired: true ,admin: false },
    component: () => import("./views/WebForm/D2"),
  },
  {
    path: "/d3/:id",
    name: "D3",
    meta: { authRequired: true  ,admin: false},
    component: () => import("./views/WebForm/D3"),
  },
  {
    path: "/d4/:id",
    name: "D4",
    meta: { authRequired: true ,admin: false },
    component: () => import("./views/WebForm/D4"),
  },
  {
    path: "/d12/:id",
    name: "D12",
    meta: { authRequired: true ,admin: false},
    component: () => import("./views/WebForm/D12"),
  },

  {
    path: "/pdf",
    name: "Pdf ",
    meta: { authRequired: true ,admin: false},
    component: () => import("./views/applicant/pdf"),
  },
  {
    path: "/applicantInvoice",
    name: "Applicant Invoice",
    meta: { authRequired: true,admin: false },
    component: () => import("./views/applicant/applicantInvoice"),
  },



  {
    path: "/citizenship_application",
    name: "citizenship application",
    meta: { authRequired: true, admin: false },
    component: () => import("./views/applicant/citizenship_application"),
  },
  {
    path: "/edit_Application/:id",
    name: "edit application",
    meta: { authRequired: true, admin: false },
    component: () => import("./views/applicant/citizenship_application"),
  },

  // {
  //   path: "/staff_users",
  //   name: "Staff Users",
  //   meta: { authRequired: true },
  //   component: () => import("./views/admin/staff_users"),
  // },
  // {
  //   path: "/admin_notifications",
  //   name: "Admin Notifications",
  //   meta: { authRequired: true },
  //   component: () => import("./views/admin/admin_notifications"),
  // },
  // {
  //   path: "/archived_staff_members",
  //   name: "Archived Staff Members",
  //   meta: { authRequired: true },
  //   component: () => import("./views/admin/archived_staff_member"),
  // },
  // {
  //   path: "/single_staff/:id",
  //   name: "Single Staff",
  //   meta: { authRequired: true },
  //   component: () => import("./views/admin/single_staff"),
  // },
  // Admin End Routing


  // Applicant Routing Start
  // {
  //   path: "/",
  //   name: "default",
  //   meta: { authRequired: true },
  //   component: () => import("./views/applicant/dashboard"),
  // },
  // {
  //   path: "/membership_application_edit",
  //   name: "membership_application_edit",
  //   meta: {
  //         authRequired: true,
  //         nav: 1,
  //   },
  //   component: () => import("./views/applicant/membership_application_edit"),
  // },
  // {
  //   path: "/view_application/:id", // ":id" represents the dynamic ID parameter
  //   name: "view_application",
  //   meta: {
  //     authRequired: true,
  //     nav: 1,
  //   },
  //   component: () => import("./views/staff/membership_application"),
  //   props: (route) => ({ id: route.params.app_id }) // Pass app_id as id prop
  // },
  // {
  //   path: "/review_application/:id", // ":id" represents the dynamic ID parameter
  //   name: "review_application",
  //   meta: {
  //     authRequired: true,
  //     nav: 1,
  //   },
  //   component: () => import("./views/staff/membership_application"),
  //   props: (route) => ({ id: route.params.app_id }) // Pass app_id as id prop
  // },
  // {
  //   path: "/old_records",
  //   name: "old_records",
  //   component: () => import("./views/applicant/old-records"),
  // },

  // {
  //   path: "/view_log",
  //   name: "View Log",
  //   meta: { authRequired: true },
  //   component: () => import("./views/applicant/view-log"),
  // },
  // {
  //   path: "/notifications",
  //   name: "Notifications",
  //   meta: { authRequired: true },
  //   component: () => import("./views/applicant/notifications"),
  // },
  // Applicant Routing End



  // Staff Routing Start
  // {
  //   path: "/application_list",
  //   name: "application list",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/application_list.vue"),
  // },
  // {
  //   path: "/staff_view_log",
  //   name: "Staff View Log",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/staff_view_log"),
  // },
  // {
  //   path: "/staff_dashboard",
  //   name: "Dashboard",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/staff_dashboard"),
  // },
  // {
  //   path: "/approved_application",
  //   name: "Approved Application",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/approved_application"),
  // },
  // {
  //   path: "/rejected_application",
  //   name: "Rejected Application",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/rejected_application"),
  // },
  // {
  //   path: "/incomplete_application",
  //   name: "Incomplete Application",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/incomplete_application"),
  // },
  // {
  //   path: "/staff_notification",
  //   name: "Staff Notification",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/staff_notification"),
  // },
  // {
  //   path: "/my_profile",
  //   name: "My Profile",
  //   meta: { authRequired: true },
  //   component: () => import("./views/staff/staff_profile"),
  // },
  // // Staff Routing End
  // {
  //   path: "/auth/login-1",
  //   name: "Login sample",
  //   meta: { authRequired: true },
  //   component: () => import("./views/sample-pages/login-sample"),
  // },

];
