import { createWebHashHistory, createRouter } from "vue-router";

// import store from '@/state/store'
import routes from './routes'

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  // Use the HTML5 history API (i.e. normal-looking routes)
  // instead of routes with hashes (e.g. example.com/#/about).
  // This may require some server configuration in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  mode: 'history',
  base: '/login',
  // Simulate native-like scroll behavior when navigating to a new
  // route and using back/forward buttons.
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {

  const logged = localStorage.getItem('email') != null || "" ? atob(decodeURIComponent(JSON.parse(localStorage.getItem('email')))) : "";
  const authRe = routeTo.matched.some(record => record.meta.authRequired);
  const authAdmin = routeTo.matched.some(record => record.meta.admin);
  const userType = String(atob(decodeURIComponent(JSON.parse(localStorage.getItem('userType')))));
  // console.log(logged)
  if (authRe && !logged) {
    return next({ path: '/Applicant-login' });
  }
  else {

    if (!authRe && logged) {
      return next({ path: '/' });
    }
    else {
      if (!authAdmin  && userType == 'admin') {
        return next({ path: '/' });
      }
      else if (authAdmin && userType == 'applicant') {
        return next({ path: '/dashboard' });
      }
      return next()
    }
  }

})

export default router
