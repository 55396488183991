
<template>
  <router-view></router-view>
</template>

<script>
// import axios from "axios";
export default {
  name: 'App',
  components: {
  },
  setup() {
    return {
      // getAuthenticatedUser() {
      //   let data = new FormData();
      //   data.append('email', atob(decodeURIComponent(JSON.parse(localStorage.getItem('email')))));
      //   axios.post(this.app_URL + 'api/getAuthenticatedUser', data)
      //     .then(res => {
      //       this.$store.state.authenticatedUserId = res.data.id;
      //       this.$store.state.authenticatedUser = res.data;
      //       // console.log(res.data)
      //     })
      //     .catch((error) => {
      //       console.log(error)
      //       // Handle errors
      //     });
      // },
    }
  },
  data() {
    return {
      app_URL: process.env.VUE_APP_URL,
    }
  },
  // mounted() {
  //   this.getAuthenticatedUser();

  // },
  // beforeMount() {
  //   this.getAuthenticatedUser();
  // },
  // beforeUnmount() {
  //   this.getAuthenticatedUser();
  // },
  // beforeMount() {
  //   this.getAuthenticatedUser();
  // },
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     vm.getAuthenticatedUser();
  //   });
  // },
  methods: {
    // getAuthenticatedUser() {
    //   let data = new FormData();
    //   data.append('email', atob(decodeURIComponent(JSON.parse(localStorage.getItem('email')))));
    //   axios.post(this.app_URL + 'api/getAuthenticatedUser', data)
    //     .then(res => {
    //       this.$store.state.authenticatedUser = res.data;
    //       this.$store.state.authenticatedUserId = res.data.id;
    //       // console.log(res.data)
    //     })
    //     .catch((error) => {
    //       console.log(error)
    //       // Handle errors
    //     });
    // },
  }
}
</script>
